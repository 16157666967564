import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "../store/configureStore";
import { router } from "../router/route";
import { ResetPasswordFormInputs } from "../models/ResetPassword";
import { ChangePasswordFormInputs } from "../models/ChangePassword";
import { CredentialFormData } from "../models/Credentials";
import { FileUploadForm, Section, CertType } from "../models/FileUpload";
import { AnswersData, AnswersType } from "../models/Answers";
import { User } from "../models/user";
import {
  ClassContent,
  ClassIDs,
  PostData,
  PostStudent,
  SharedUserInfo,
  UserStatus,
  UserType,
} from "../models/AgentParamTypes";
import { EmailPostType } from "../models/EmailDataTypes";
import { TranslationItem } from "../../translation/translationType";

const sleep = () => new Promise((resolve) => setTimeout(resolve, 500));

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.getState().account.user?.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep();

    return response;
  },
  (error: AxiosError) => {
    const { data, status } = error.response as AxiosResponse;
    switch (status) {
      case 400:
        if (data.errors) {
          const modelStateErrors: string[] = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modelStateErrors.push(data.errors[key]);
            }
          }
          throw modelStateErrors.flat();
        }
        console.error(data.message);
        break;
      case 401:
        console.error(data.message);
        break;
      case 403:
        console.error("You are not allowed to do that!");
        break;
      case 500:
        router.navigate("/server-error", { state: { error: data } });
        break;
      default:
        break;
    }

    return Promise.reject(error.response);
  }
);

function createFormData(item: any) {
  let formData = new FormData();
  for (const key in item) {
    formData.append(key, item[key]);
  }
  return formData;
}

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  getFile: (url: string) =>
    axios.get(url, { responseType: "arraybuffer" }).then(responseBody),
  post: (
    url: string,
    body: {},
    headers: {} = { "Content-type": "application/json" }
  ) => axios.post(url, body, { headers }).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  delete: (url: string) => axios.delete(url).then(responseBody),
  deletePost: (url: string, body: {}) => {
    axios.delete(url, { data: body }).then(responseBody);
  },
  postForm: (url: string, data: FormData) =>
    axios
      .post(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
  putForm: (url: string, data: FormData) =>
    axios
      .put(url, data, {
        headers: { "Content-type": "multipart/form-data" },
      })
      .then(responseBody),
};

const Account = {
  login: (values: any) => requests.post("account/login", values),
  login_sso: (values: any) => requests.post("account/login_sso", values),
  register: (values: any) => requests.post("account/register", values),
  currentUser: () => requests.get("account/currentUser"),
  fetchAddress: () => requests.get("account/savedAddress"),
  ConfirmEmail: (ConfirmEmail: any) =>
    requests.post("account/ConfirmEmail", ConfirmEmail),
  forgotPassword: (email: ResetPasswordFormInputs) =>
    requests.post("account/forgotPassword", email),
  changePassword: (changePassword: ChangePasswordFormInputs) =>
    requests.post("account/changePassword", changePassword),
  updateAccount: (values: any) => requests.put("account/updateAccount", values),
  checkEmailExist: (email: string) =>
    requests.post("account/checkEmailExists", email),
};

const Credentials = {
  UpdateCredentials: (credentials: CredentialFormData) =>
    requests.putForm("user/credentials", createFormData(credentials)),
};

const Welcome = {
  UpdateWelcomeComplete: (complete: CredentialFormData) =>
    requests.putForm("user/welcome", createFormData(complete)),
};

const FileUpload = {
  UploadFile: (file: FileUploadForm) =>
    requests.postForm("File", createFormData(file)),
  GetFileInfo: (section: Section, portfolioId: number) =>
    requests.get(
      `File/filesInfo?section=${section}&portfolioId=${portfolioId}`
    ),
  GetFileInfoByCertType: (certType: CertType, portfolioId: number) =>
    requests.get(
      `File/filesInfoByCertType?certType=${certType}&portfolioId=${portfolioId}`
    ),
  GetFiles: (fileId: number) => requests.getFile(`File/${fileId}`),
  DeleteFile: (fileId: number) => requests.delete(`File/${fileId}`),
  GetAllFilesByPortfoliId: (portfolioId: number) =>
    requests.get(`File/GetAllFilesByPortfolioId/${portfolioId}`), // T0 get all files for Final PDF
};

const Answers = {
  GetAnswers: (description: string, portfolioId: number, type: AnswersType) =>
    requests.get(
      `Answers/GetByDescriptionPortfolioType?Description=${description}&PortfolioId=${portfolioId}&Type=${type}`
    ),
  GetAnswersByPortfoliId: (portfolioId: number) =>
    requests.get(`Answers/GetAnswersByPortfolioId/${portfolioId}`),
  InsertorUpdateAnswers: (answers: AnswersData[]) =>
    requests.post("Answers", answers),
};

const Books = {
  GetBooks: (PortfolioId: number) =>
    requests.get(`Books/GetBooksByPortfolioId/${PortfolioId}`),
  DeleteBooks: (id: string) => requests.delete(`Books/${id}`),
  PostBooks: (PortfolioId: number, books: any) =>
    requests.post(`Books/${PortfolioId}`, books),
};

const EarlyChildhoodAssociations = {
  GetEarlyChildhoodAssociations: (PortfolioId: number) =>
    requests.get(
      `EarlyChildhoodAssociations/GetEarlyChildhoodAssociationsByPortfolioId/${PortfolioId}`
    ),
  DeleteEarlyChildhoodAssociations: (id: string) =>
    requests.delete(`EarlyChildhoodAssociations/${id}`),
  PostEarlyChildhoodAssociations: (
    PortfolioId: number,
    earlyChildhoodAssociations: any
  ) =>
    requests.post(
      `EarlyChildhoodAssociations/${PortfolioId}`,
      earlyChildhoodAssociations
    ),
};

const MentalHealthServices = {
  GetMentalHealthServices: (PortfolioId: number) =>
    requests.get(
      `MentalHealthService/GetMentalHealthServicesByPortfolioId/${PortfolioId}`
    ),
  DeleteMentalHealthServices: (id: string) =>
    requests.delete(`MentalHealthService/${id}`),
  PostMentalHealthServices: (PortfolioId: number, mentalHealthServices: any) =>
    requests.post(`MentalHealthService/${PortfolioId}`, mentalHealthServices),
};

const WebsiteArticles = {
  GetWebsiteArticles: (PortfolioId: number) =>
    requests.get(
      `WebsitesProvidingInformation/GetWebsitesProvidingInformationByPortfolioId/${PortfolioId}`
    ),
  DeleteWebsiteArticle: (id: string) =>
    requests.delete(`WebsitesProvidingInformation/${id}`),
  PostWebsiteArticles: (PortfolioId: number, websiteArticles: any) =>
    requests.post(
      `WebsitesProvidingInformation/${PortfolioId}`,
      websiteArticles
    ),
};
const PDFViewer = {
  GetPDFByType: (description: string, portfolioId: number, type: AnswersType) =>
    requests.get(
      `PreviewPdf?Description=${description}&PortfolioId=${portfolioId}&Type=${type}`
    ),
  GenerateFinalPDF: (PortfolioId: number, Version: Section) =>
    requests.get(`PreviewPdf/GenerateFinalPDF/${PortfolioId}/${Version}`),
};
const Progress = {
  GetProgressResults: (PortfolioId: number) =>
    requests.get(`Progress/GetAllProgress/${PortfolioId}`),
  GetCompentencyResults: (PortfolioId: number) =>
    requests.get(`Progress/CompetencyData/${PortfolioId}`),
  GetCompentencyResultsByType: (PortfolioId: number, CompType: string) =>
    requests.get(`Progress/CompetencyDataByType/${PortfolioId}/${CompType}`),
  GetUserProgress: (EndDate: string | undefined) =>
    requests.get(`Progress/UserProgress?endDate=${EndDate}`),
  GetUserProgressAssignedToTrainer: (postData: PostData) =>
    requests.post("Progress/UserProgressAssignedToTrainer", postData),

  GetUserBiLingualProgress: (EndDate: string | undefined) =>
    requests.get(`Progress/UserBiLingualProgress?endDate=${EndDate}`),
  GetCandidateUserbyState: (EndDate: string | undefined) =>
    requests.get(`Progress/GetCandidateUserbyState?endDate=${EndDate}`),
};

const Admin = {
  GetAllUsersWithProgress: (EndDate: string | undefined) =>
    requests.get(`/User?endDate=${EndDate}`),
  GetSharedUsers: () => requests.get(``),
};

const Users = {
  GetUserById: (id: number) => requests.get(`User/${id}`),
  updateUser: (values: User) => requests.put("User/update", values),
};

const SharedAccess = {
  //Trainer/OtherViewer calls
  GetUserHasAccessToPortfolio: (sharedUserInfo: SharedUserInfo) =>
    requests.get(`SharedAccess/UserHasAccessToPortfolio, ${sharedUserInfo}`),
  GetSharedListAccessUserId: (userId: number) =>
    requests.get(`SharedAccess/ListSharedAccess?userId=${userId}`),

  //Candidate's calls
  ListOfUsersByType: (UserType: UserType) =>
    requests.post("SharedAccess/GetListOfUsersByType", UserType),
  RequestSharedAccess: (sharedUserInfo: SharedUserInfo) =>
    requests.post("SharedAccess/RequestSharedAccess", {
      ...sharedUserInfo,
    }),
  ApproveSharedAccess: (status: UserStatus) =>
    requests.put(`SharedAccess/ApproveSharedAccess`, status),
  ListOfAccessShared: (portfolioId: number) =>
    requests.get(`SharedAccess/ListAccessShared?portfolioId=${portfolioId}`),
  GetNumberOfAccessGranted: (portfolioId: number) =>
    requests.get(
      `SharedAccess/NumberOfAccessGrantedByPortfolio?portfolioId=${portfolioId}`
    ),
};

const TrainerClasses = {
  CreateNewClass: (classContent: ClassContent) =>
    requests.post("TrainingClassesManagement/CreateClass", classContent),

  AddStudentToClass: (data: PostStudent) =>
    requests.post("TrainingClassesManagement/AddProfileToClass", data),
  GetClassRoster: (classId: number) =>
    requests.get(
      `TrainingClassesManagement/GetClassRoster?trainingClassId=${classId}`
    ),
  GetTrainerClassList: (userId: number) =>
    requests.get(
      `TrainingClassesManagement/GetListOfClassesForTrainer?instructorId=${userId}`
    ),
  GetStudentClassList: (portfolioId: number) =>
    requests.get(
      `TrainingClassesManagement/GetListOfClassesForPortfolio?portfolioId=${portfolioId}`
    ),
  RemoveClass: (data: ClassIDs) =>
    requests.deletePost("TrainingClassesManagement/RemoveClass", data),
  RemoveFromClassRoster: (postStudent: PostStudent) =>
    requests.deletePost(
      "TrainingClassesManagement/RemoveProfileFromClass",
      postStudent
    ),
};

const EmailTemplate = {
  GetEmailTemplateByType: () =>
    requests.get(`EmailTemplate/GetEmailTemplates?emailType`),
  GetEmailTemplateById: (id: number) =>
    requests.get(`EmailTemplate/GetEmailTemplate?id=${id}`),
  PostEmailTemplate: (postTemplate: EmailPostType) =>
    requests.post("/EmailTemplate/CreateUpdateEmailTemplate", postTemplate),
  DeleteEmailTemplate: (id: number) =>
    requests.delete(`EmailTemplate/DeleteEmailTemplate?id=${id}`),
};

const YourCouncil = {
  // Get the authentication token
  getToken: () => requests.get("YourCouncil/getToken"),

  // Get certification application information by application ID
  getCertificationApplicationInfo: (applicationId: string) =>
    requests.get(
      `YourCouncil/getCertificationApplicationInfo?applicationId=${applicationId}`
    ),

  // Attach a PDF document to a specific Certification Application record
  attachPdfToRecord: (
    applicationId: string,
    fileName: string,
    base64FileContent: string
  ) => {
    const body = {
      applicationId,
      fileName,
      base64FileContent,
    };
    return requests.post("YourCouncil/attachPdfToRecord", body);
  },

  // Get certification applications by contact ID
  getCertificationApplicationsByContactId: (contactId: string) =>
    requests.get(
      `YourCouncil/getCertificationApplicationsByContactId?contactId=${contactId}`
    ),
};

const spanish = {
  getTranslations: () => requests.get(`spanish`),
  googleTranslate: (text: string, targetLanguage: string) =>
    requests.get(
      `spanish/googleTranslate?text=${text}&target=${targetLanguage}`
    ),
  upsert: (values: TranslationItem) => requests.post(`spanish/upsert`, values),
};

const SAML2 = {
  // Initiates the Single Sign-On (SSO) process
  initiateSSO: (role: string) =>
    requests.get(`Saml2/sso?role=${role}`),

  // Initiates the Single Logout (SLO) process
  initiateSLO: () => requests.get("Saml2/slo"),

  // Handles the Assertion Consumer Service (ACS) after SAML response
  handleACS: (samlResponse: string) =>
    requests.post("Saml2/acs", { SAMLResponse: samlResponse }),
};


const agent = {
  Account,
  Credentials,
  Welcome,
  FileUpload,
  Answers,
  Books,
  EarlyChildhoodAssociations,
  MentalHealthServices,
  WebsiteArticles,
  PDFViewer,
  Progress,
  Admin,
  Users,
  SharedAccess,
  TrainerClasses,
  EmailTemplate,
  YourCouncil,
  spanish,
  SAML2,
};

export default agent;
