import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import agent from "../../app/api/agent";
import { useAppDispatch } from "../../app/store/configureStore";
import { signInUserSSO } from "./accountSlice";

interface UserDto {
  id: string;
  username: string;
  token: string; // Raw token
  role: string;
  welcomeComplete: boolean;
  email: string;
  firstName: string;
  lastName: string;
  credentialName?: string | null; // Nullable
  credentialTypeId?: number | null; // Nullable
  cdaCouncilCandidateId?: number | null; // Nullable
  languageSpecialization?: string | null; // Nullable
  portfolioId?: number | null; // Nullable
  homeState?: string | null; // Nullable
  phoneNumber?: string | null; // Nullable
  homeZipCode?: string | null; // Nullable
  isActive: boolean;
  isSpanish?: boolean;
}

const Login_sso: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();


  // Parse the query string to retrieve the token
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  // Retrieve the "role" from the query string parameter
  const role = params.get("role") ?? "";

  if (role.length === 0) {
    window.location.href =
      "https://cfprportal-uat.azurewebsites.net/en-US/";
  }

  useEffect(() => {
    const handleToken = async () => {
      if (token) {
        sessionStorage.setItem("authToken", token);

        const jwt_decode = require("jwt-decode");

        const decoded: any = jwt_decode(token);

        const userDto: UserDto = {
          id: decoded.id,
          username: decoded.username,
          token: token,
          role: decoded.role,
          welcomeComplete: decoded.welcomecomplete,
          email: decoded.email,
          firstName: decoded.firstname,
          lastName: decoded.lastname,
          credentialName: decoded.credentialname,
          credentialTypeId: decoded.credentialtypeid,
          cdaCouncilCandidateId: decoded.cdacouncilcandidateId,
          languageSpecialization: decoded.languagespecialization,
          portfolioId: decoded.portfoliosid,
          homeState: decoded.homestate,
          phoneNumber: decoded.phonenumber,
          homeZipCode: decoded.homezipcode,
          isSpanish: decoded.isSpanish,
          isActive: decoded.isActive,
        };
        await dispatch(signInUserSSO(userDto));
        navigateUser(userDto);
      } else {
        console.error("No token found in query string");

        agent.SAML2.initiateSSO(role)
          .then((response) => {
            console.log("SSO initiated:", response);
          })
          .catch((error) => {
            console.error("Error initiating SSO:", error);
          });
      }
    };

    handleToken();
  }, [location, navigate, token, role, dispatch]);

  /*   useEffect(() => {
    if (user) {
      navigateUser(user); // Navigate based on the user once the state is updated
    }
  }, [user]); // Add user as a dependency to run when user is set
 */
  // Function to handle navigation based on user role
  const navigateUser = (u: UserDto) => {
    const admin = u.role === "CSI Admin" || u.role === "Council Admin";
    const trainer = u.role === "Trainer";
    const otherViewer = u.role === "Other Viewer";

    if (admin) {
      navigate(location.state?.from || "/admin/dashboard");
    } else if (!admin && !trainer && !otherViewer && u.welcomeComplete) {
      navigate(location.state?.from || "/myprogresssummary");
    } else if (trainer) {
      navigate(location.state?.from || "/trainer/students");
    } else if (otherViewer) {
      navigate(location.state?.from || "/editprofile");
    } else if (!admin && !trainer && !otherViewer && !u.welcomeComplete) {
      navigate(location.state?.from || "/welcome");
    } else if (otherViewer) {
      navigate(location.state?.from || "/trainer/manageusers");
    }
  };

  return <div>Logging in...</div>;
};

export default Login_sso;
